import React, { useEffect } from "react";
import { HeadLine } from "../../components/component";
import Intro from "../../components/dao/Intro";
import SpotifyPlayer from "../../components/SpotifyPlayer";
import {
  Hero,
  AfterConcert,
} from "../../components/component";
import Meta from "../../components/Meta";

const Homepage = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.15]");
  }, []);

  return (
    <>
      <Meta title="Homepage" />
      <Hero />
      {/* <Intro /> */}
      <AfterConcert classes="dark:bg-jacarta-800 relative py-24" bgWhite={true} />
      <div className="container my-8">
        <HeadLine
          text="KIG LIVE's Concert Wrap 2023 Playlist"
          classes="font-display text-jacarta-700 text-center text-7xl mb-8 dark:text-white"
        />
        <SpotifyPlayer iframe="https://open.spotify.com/embed/playlist/3kTpL7QtfitVZwYmeFwLdD" />
      </div>
    </>
  );
};

export default Homepage;
