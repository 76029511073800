import React from 'react';

const SpotifyPlayer = ({iframe}) => {
  return (
    <div className="spotify-embed-container w-full bg-transparent">
      <iframe
        src={iframe}
        height="450"
        frameBorder="0"
        allowTransparency="true"
        allow="encrypted-media"
        title="Spotify Playlist"
        className='w-full bg-transparent'
      ></iframe>
    </div>
  );
};

export default SpotifyPlayer;