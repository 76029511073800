import React, { useState } from "react";
import { Tabs, TabPanel } from "react-tabs";

const Intro = () => {
  const [videoModal, setvideoModal] = useState(false);
  return (
    // <!-- Intro -->
    <section className="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]">
      <div className="container">
        <div className="lg:flex lg:justify-between items-center">
          {/* <!-- Image --> */}

          <div className="flex items-center justify-center lg:w-[57%]">
            {/* <!-- content --> */}
            <Tabs>
              <div className="tab-content flex-1">
                <TabPanel>
                  <div className=" relative">
                    <figure className="flex items-center justify-center">
                      <button
                        onClick={() => setvideoModal(true)}
                        className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-16 w-16 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90 z-[9]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className="h-8 w-8 fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                        </svg>
                      </button>
                      <img
                        src="/images/intro_kiglive.jpg"
                        alt="benifit"
                        className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
                      />
                      <img
                        src="/images/dao/3d_elements_circle.png"
                        alt=""
                        className="absolute animate-spin-slow"
                      />
                    </figure>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="relative">
                    <figure className="flex items-center justify-center">
                      <img
                        src="/images/dao/benefit_dao_2.jpg"
                        alt="benifit"
                        className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
                      />
                      <img
                        src="/images/dao/3d_elements_circle.png"
                        alt="benifit"
                        className="absolute animate-spin-slow"
                      />
                    </figure>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="relative">
                    <figure className="flex items-center justify-center">
                      <img
                        src="/images/dao/benefit_dao_3.jpg"
                        alt="benifit"
                        className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
                      />
                      <img
                        src="/images/dao/3d_elements_circle.png"
                        alt="benifit"
                        className="absolute animate-spin-slow"
                      />
                    </figure>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="relative">
                    <figure className="flex items-center justify-center">
                      <img
                        src="/images/dao/benefit_dao_4.jpg"
                        alt="benifit"
                        className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
                      />
                      <img
                        src="/images/dao/3d_elements_circle.png"
                        alt="benifit"
                        className="absolute animate-spin-slow"
                      />
                    </figure>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className=" relative">
                    <figure className="flex items-center justify-center">
                      <img
                        src="/images/dao/benefit_dao_5.jpg"
                        alt="benifit"
                        className="rounded-full border border-jacarta-100 p-14 dark:border-jacarta-600"
                      />
                      <img
                        src="/images/dao/3d_elements_circle.png"
                        alt="benifit"
                        className="absolute animate-spin-slow"
                      />
                    </figure>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>

          {/* <!-- Info --> */}
          <div className="py-10 lg:w-[55%] lg:pl-24">
            <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
              Welcome To KIG Live, Where We CREATE LOUDER Experiences That Resonate With Music Enthusiasts Everywhere
            </h2>
            <p className="mb-8 text-lg font-normal text-normal dark:text-jacarta-300">
              We are dedicated to bringing memorable moments to life, allowing people to live, relive, and share their passion for music. Our mission is to become the most sought-after promoter, capturing the hearts and minds in the world of live events.

            </p>
            <a href="/about-us" className="flex items-center gap-2 hover:gap-4 font-display text-red transition-all duration-300 ease-in-out">
              <span>See More</span>
              <img src="/images/icons/arrow-right.svg" width={20} />
            </a>
          </div>
        </div>
      </div>
      <div
        className={
          videoModal ? "modal lightbox fade show block" : "modal lightbox fade"
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-xl w-full z-[9999]">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body p-0 relative">
              <button
                onClick={() => setvideoModal(false)}
                type="button"
                className="btn-close position-absolute top-0 end-0 p-3 z-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#fff"
                  className="h-6 w-6"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              <div
                id="lightboxCarousel-d7ewe4ig"
                className="lightbox-carousel carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="position-absolute top-50 start-50 translate-middle text-white">
                      <div className="spinner-border" role="status"></div>
                    </div>
                    <div className="ratio ratio-16x9">
                      <iframe
                        src="https://www.youtube.com/embed/Xkxz4qtPi2Y"
                        title="YouTube video player"
                        allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- end intro -->
  );
};

export default Intro;
